:root {
    --Background-color: white;
    --Text-color: black;
}

.FooterDiv {
    background-color: var(--Background-color);
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    min-height: 400px;
    min-height: fit-content;
    padding: 50px 50px;
}

.FooterUpperDiv {
    height: 100%;
    width: 100%;
    display: flex;
}

.FooterLowerDiv {
    height: 50px;
    width: 100%;
    padding: 4px 70px;
    display: flex;
    justify-content: space-between;
}

.FooterUlDiv {
    height: 100%;
    width: 100%;
    display: flex;
    padding: 40px 0;
    align-items: flex-start;
    justify-content: space-around;
}

.FooterUl {
    margin: 0 0;
    padding: 0 0;
}

.FooterUlHeader {
    font-size: larger;
    font-weight: 500;
}

.FooterUlLi {
    padding: 4px 4px;
    font-size: medium;
    opacity: .8;
}

.FooterUlLi:hover{
    opacity: 1;
}

.FooterUlLi>a {
    color: var(--Text-color);

}

.FooterSubscribeHeader{
    width: 100%;
    text-align: start;
    margin-bottom: 10px;
    font-weight: 600;
}

.FooterSubscribeDiv {
    box-sizing: border-box;
    padding: 40px 50px;
    height: 100%;
    width: 500px;
    
}

.FooterSubscribeForm{
    height: 100%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.274);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 30px;
}

.FooterSubscribeInputDiv{
    width: 100%;
    height: 50px;
    
}

.FooterSubscribeInputDiv>input{
    height: 100%;
    width: 100%;
    outline: none;
    color: var(--Text-color);
    font-size: 100%;
    background-color: rgba(0, 0, 0, 0.165);
    padding: 5px 10px;
    border-radius: 5px;
}

.FooterSubscribeButton{
    width: 100%;
    height: 50px;
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity));
    color: black;
    margin-top: 10px;
    border-radius: 5px;
    font-weight: 600;
}

.FooterLogo{
    height: 60px;
    width: fit-content;
}

.FooterLogo>img{
    height: 100%;
}
.FooterSocial{
    display: flex;
    gap: 10px;
}

.FooterSubscribeButton:hover{
    /* outline: 1px solid black; */
   
}

.SocialIconFooter:hover , .SocialIconFooter:hover>svg{
    cursor: pointer;
    fill: black !important;
    background-color:yellow;
    border-color: yellow;
}

.SocialIconFooter{
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@media screen and (max-width:768px) {
    .FooterUpperDiv {
        flex-wrap: wrap-reverse;
    }

    .FooterLowerDiv{
        padding-left: 0px;
        padding-right: 0px;
    }

    .FooterSubscribeDiv{
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .FooterSubscribeForm{
        padding: 30px 30px;
    }
}

@media screen and (max-width:528px) {
    .FooterUlDiv{
       flex-wrap: wrap;
       gap: 30px;
    }

    .FooterLowerDiv{
        flex-wrap: wrap-reverse;
        height: fit-content;
        justify-content: space-around;
    }
   
}