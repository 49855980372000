.FilterOuter {
  position: sticky;
  padding: 10px 20px;
  display: block;
}

.FilterHeading {
  font-size: xx-large;
  font-weight: 700;
}

.FilterSubHeading {
  font-size: large;
  font-weight: 600;
}

.form-check {
  padding: 10px 0;
}

.FilterCheckbox {
  padding: 5px 5px;
  display: flex;
  align-items: center;
}

.FilterCheckbox > input {
  margin-right: 10px;
  cursor: pointer;
}

.FilterCheckbox > label {
  color: rgba(0, 0, 0, 0.755);
  font-size: medium;
}

.FilterCheckbox > input:checked {
  background-color: yellow;
}
.FilterButton {
  display: none;
  cursor: pointer;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.324);
    box-sizing: border-box;
    padding: 2px 10px;
    width: fit-content;
    align-items: center;
    align-self: flex-end;
    margin-right: 10px;
}
.FilterClose {
  display: none;
}
.ApplyNow {
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  padding: 8px 20px;
  background-color: rgb(253, 224, 71);
  border-radius: 10px;
}
.ApplyNow:hover {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.LeftOuter {
  padding: 0 20px;
  padding-bottom: 20px;
}
.SortActive{
    background-color: rgb(253, 224, 71);
}
@media screen and (max-width: 1024px) {
  .LeftOuter {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0;
    position: relative;
  }
  .FilterButton {
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.324);
    box-sizing: border-box;
    padding: 2px 10px;
    width: fit-content;
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-right: 10px;
  }
  .FilterButton:hover {
    background-color: rgb(253, 224, 71);
    transition: all 0.3s;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
  .FilterOuter {
    width: 100%;
    background-color: white;
  }
  .FilterHeaderDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .FilterClose {
    display: inline-block;
    cursor: pointer;
  }
}
