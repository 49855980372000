.CarouselWrapper{
   
    width: 100%;
    display: flex;
    align-items: center;
}

.CarouselImage{

    width: 100%;
    object-fit: contain;
    aspect-ratio: 16/9;
}