.loader {
    color: rgb(124, 124, 124);
    font-family: "Poppins",sans-serif;
    font-weight: 500;
    font-size: 25px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 40px;
    padding: 10px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 8px;
  }
  
  .words {
    overflow: hidden;
  }
  
  .word {
    display: block;
    height: 100%;
    padding-left: 6px;
    color: rgb(253,224,71);
    animation: spin_4991 4s infinite;
  }
  
  @keyframes spin_4991 {
    10% {
      -webkit-transform: translateY(-105%);
      transform: translateY(-105%);
    }
  
    25% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  
    35% {
      -webkit-transform: translateY(-205%);
      transform: translateY(-205%);
    }
  
    50% {
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
    }
  
    60% {
      -webkit-transform: translateY(-305%);
      transform: translateY(-305%);
    }
  
    75% {
      -webkit-transform: translateY(-300%);
      transform: translateY(-300%);
    }
  
    85% {
      -webkit-transform: translateY(-405%);
      transform: translateY(-405%);
    }
  
    100% {
      -webkit-transform: translateY(-400%);
      transform: translateY(-400%);
    }
  }
  