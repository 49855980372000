.Card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 290px;
  width: 200px;
  border: none;
  border: 1px solid rgba(105, 105, 105, 0.098);
  outline: 1px solid rgba(105, 105, 105, 0.098);
  border-radius: 10px;
  overflow: hidden;
}
.imageclass {
  width: 100%;
  height: 144px;
}
.CardImage {
  width: 100%;
  height: 50%;

  object-fit: cover;
  display: flex;

  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.ImageContainerSkeleton {
  width: 100%;
  height: 100%;
  display: block;
  padding: 6px 10px;
}

.Card:hover .CardImage {
  filter: drop-shadow(1px 1px 4px black);
  cursor: pointer;
}
.CardImage > img {
  max-width: 100%;
  max-height: 100%;
  height: 144px;
}

.contentOuter {
  box-sizing: border-box;
  height: 50%;
  background-color: white;
  width: 100%;
  padding: 8px 10px;
}
.Timer {
  box-sizing: border-box;
  background-color: rgb(248, 248, 248);
  height: 15px;
  width: fit-content;
  padding: 0 4px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6px;
}
.SvgTimerIcon {
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
}
.TimerText {
  font-weight: 700;
  width: fit-content;
  font-size: 9px;
  color: rgb(54, 54, 54);
}
.Desc {
  height: calc(100% - 21px);
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.Title {
  height: 60%;
  width: 100%;
}
.TitleText {
  box-sizing: border-box;
  width: 100%;
  height: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(31, 31, 31);
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 6px;
}
.price {
  box-sizing: border-box;
  padding: 5px 0;
  height: 40%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.Stock {
  font-size: 12px;
  color: #666;
}
.PriceText {
  padding-left: 3px;
  padding-bottom: 6px;
  font-size: 12px;
  color: rgb(31, 31, 31);
  font-weight: 600;
}
.PriceText::before {
  content: "₹";
}
.AddButton {
  width: 66px;
  height: 31px;
}
.AddButton > button {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: rgb(247, 255, 249);
  border: none;
  outline: none;
  border: 1px solid green;
  border-radius: 5px;
  color: green;
}
.CartCardOuter {
  width: 100%;
  height: 250px;
  padding: 20px 20px;
}

.CartCardWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5%;
}

.ImageCart {
  box-sizing: border-box;
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
}

.ImageCart > img {
  max-width: 100%;
  max-height: 100%;
}

.CartDetails {
  box-sizing: border-box;
  padding: 20px 10px;
  height: 100%;
  width: 70%;
}
.ProductNameCart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.ProductNameCart > h3 {
  font-size: x-large;
  font-weight: 700;
}

.PriceCart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.PriceCart > p {
  padding: 10px 0;
  font-size: medium;
  font-weight: 600;
}

.PriceCart > p::before {
  content: "₹";
  margin-right: 2px;
}

.PriceCart > p::after {
  content: "MRP";
  margin-left: 2px;
  font-size: small;
  color: rgba(0, 0, 0, 0.456);
}

.EditQuantity {
  padding: 5px 5px;
  border-radius: 50%;
  background-color: yellow;
  cursor: pointer;
}

.EditQuantity:hover {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.QuantityCart {
  box-sizing: border-box;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.QuantityCart > p {
  background-color: whitesmoke;
  box-sizing: border-box;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.QuantityCart > p > input:focus {
  border: none;
  outline: none;
}

.QuantityCart > p::before {
  content: "Qty : ";
}

@media screen and (max-width: 1440px) {
  .ImageCart {
    width: 40%;
  }
  .CartDetails {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .ImageCart {
    padding: 0px 0px;
  }
  .CartDetails {
    width: fit-content;
  }
  .CartCardOuter {
    height: 200px;
  }
}

@media screen and (max-width: 425px) {
  .CartDetails {
    padding: 20px 0px;
  }
}
