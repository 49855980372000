.DetailsOuter {
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  height: fit-content;
  padding: 10px 100px;
  align-items: flex-start !important;
}

.ImageWrapperDiv {
  width: 40%;
  box-sizing: border-box;
}

.DetailsImageDiv {
  padding: 100px 50px;
  border-radius: 30px;
  width: 100%;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
}

.ProductDetailsDiv {
  width: 60%;
  box-sizing: border-box;
  padding: 20px 50px;
}

.DetailsImageOuter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.ImageWrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: fit-content;
  height: fit-content;
}

.ImageWrap > img {
  max-width: 100%;
  max-height: 100%;
  min-width: 200px;
  min-height: 200px;
}

.ProductNameHeader > h3 {
  font-size: large;
  font-weight: 700;
}

.AddToCartWrapper {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DescHeader {
  box-sizing: border-box;
  padding: 20px 0;
}

.DescHeader > h3 {
  font-weight: 700;
  font-size: medium;
  margin: 10px 0;
  color: rgba(0, 0, 0, 0.657);
}

.ProductInfo {
  box-sizing: border-box;
  padding: 20px 0;
}

.ProductInfo > h2 {
  padding: 10px 0;
  font-size: medium;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.657);
}

.ProductTable {
  width: 100%;
  padding: 15px 5px;
}

.ProductTableRow {
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0;
  display: flex;
  gap: 10px;
}

.QuantityIcon{
  font-size: x-large;
  height: fit-content;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProductTableTd1 {
  min-width: fit-content;
  max-width: 70%;
  width: 50%;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  flex-wrap: wrap;
}

.ProductTableTd2 {
  width: 50%;
  display: flex;
  font-size: 15px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.InputIcon {
  display: flex;
  justify-content: space-between;
  font-size: medium;
  font-weight: 600;
}

.DeliverHeader {
  font-size: x-large;
  font-weight: 700;
  padding: 10px 0;
}

.DeliverDiv {
  padding: 10px 0;
}

.EditIcon {
  padding: 5px 5px;
  border-radius: 50%;
}

.EditIcon:hover {
  background-color: yellow;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.DeliverPinCode {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DeliverPinCode > input {
  box-sizing: border-box;
  height: 100%;
  width: 95%;
  padding: 5px 10px;
  border-radius: 10px;
  outline: 1px solid black;
}

.DeliverPinCode > input:focus {
  outline: 1px solid black;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.IconOk {
  padding: 5px 5px;
  border-radius: 50%;
}

.IconOk:hover {
  background-color: yellow;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.RatingsDiv {
  padding: 10px 100px;
  width: 100%;
}

.RatingsWrap {
  box-sizing: border-box;
  background-color: white;
  width: 100%;
  padding: 20px 20px;
  height: fit-content;
  min-height: 100px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}

.RatingsHeader {
  font-size: x-large;
  font-weight: 700;
}

.RatigsStars{
  box-sizing: border-box;
  width: 40%;
  box-sizing: border-box;
  padding: 10px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.StarReview{
  font-size: x-large;
}

.H1Ratings{
  width: 100%;
  font-size: xx-large;
  font-weight: 700;
}

.UserReviewStatement>p{
  font-size: medium;
  color: rgba(0, 0, 0, 0.62);
  padding: 5px 5px;
}

.UserReviewsDiv{
  width: 60%;
  display: flex;
  gap: 10px;
  padding: 10px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.UserReviewHeader{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.UserPhoto{
  width: 20px;
  height: 20px;
  background-color: black;
  border-radius: 50%;
}

.UserName{
  font-weight: 500;
  font-size: medium;
}

.AverageStars{
  font-size: xx-large;
  font-weight: 700;
  margin-left: 10px;
}

.RatingsStarDivWrap{
  display: flex;
  justify-content: space-between;
}

.writeReview{
  padding: 20px 0px;
}

.writeReview>h1{
  font-size: x-large;
  font-weight: 700;
}

.writeReview>p{
  font-size: medium;
  padding: 5px 0px;
}

.UserTakingReview{
  padding: 5px 10px;
}

.UserStarReview{
  font-size: xx-large;
}

.WriteReviewButton{
  padding: 15px 20px;
  border-radius: 50px;
  background-color: #FDE047;
  font-size: medium;
  font-weight: 600;
}

.StarButton{
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.star{
  font-size: 50px;
}

.on {
  color: #FDE047;
}
.off {
  color: #ccc;
}

.ProductPrice {
  font-size: x-large;
  font-weight: 700;
}

.ProductPriceDiv {
  padding: 10px 0;
}

.AverageStarsDiv{
  height: 100px;
  width: 100px ;
  background-color: blueviolet;
}

.ProductPrice::before {
  content: "₹";
  margin-right: 2px;
}

.ProductPrice::after {
  content: " MRP";
  font-size: small;
  color: rgba(0, 0, 0, 0.603);
}

.AddToCartButton {
  width: 100%;
  padding: 15px 0 !important;
  display: flex !important;
  font-size: medium !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.AddToCartButton:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.AddGap {
  gap: 10px;
}

.laptopProduct {
  display: block;
}

/* @media screen and (max-width: 1024px) {
  .DetailsOuter {
    padding: 10px 10px !important;
    padding-top: 10px;
  }
} */

/* @media screen and (max-width: 768px) {
  .DetailsOuter {
    flex-wrap: wrap;
    align-items: flex-start !important;
    flex-direction: column;
    justify-content: flex-start !important;
  }
  .DetailsImageDiv {
    width: 100%;
    padding: 20px 20px;
  }
  .ImageWrapperDiv{
    width: 90%;
  }
  .ProductDetailsDiv {
    width: 100%;
    padding: 15px 5px;
  }
  .AddToCartButton {
    padding: 10px 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
  }
} */
.outer {
  margin-top: 8rem;
}

.mobileProduct {
  display: none;
}
@media screen and (max-width: 1024px) {
  .DetailsOuter {
    padding: 10px 30px;
  }

  .outer {
    margin-top: 3rem;
  }
  
  .RatingsDiv{
    padding: 10px 30px;
  }
}

@media screen and (max-width: 850px) {
  .ImageWrapperDiv {
    width: 50%;
  }
  .ProductDetailsDiv {
    width: 50%;
  }
}
@media screen and (max-width: 630px) {
  .DetailsOuter {
    flex-direction: column;
  }

  .ImageWrapperDiv {
    width: 100%;
  }

  .ProductDetailsDiv {
    width: 100%;
  }

  .mobileProduct {
    display: block;
  }

  .laptopProduct {
    display: none;
  }

  .RatigsStars{
    flex-direction: column !important;
    padding: 20px 50px !important;
  }

  .writeReview{
    padding: 20px 10px !important;
  }
}

.UserReviewOuter{
  width: 100%;
  height: 100px;
}

@media screen and (max-width:870px) {
  .RatigsStars{
    padding: 10px 10px;
  }
  .RatingsStarDivWrap{
    gap: 0px;
  }
}

@media screen and (max-width:880px) {
  .RatingsStarDivWrap{
    flex-wrap: wrap;
    padding: 20px 50px;
  }
  .RatigsStars{
    width: 100%;
  }

  .RatingsWrap{
    padding: 10px 0px;
  }

  .RatingsStarDivWrap{
    padding: 10px 10px;
  }

  .H1Ratings{
    font-size: x-large;
  }

  .UserReviewsDiv{
    width: 100%;
    padding: 40px 10px;
  }
  .RatigsStars{
    flex-direction: row;
    padding: 0px 0px;
    justify-content: space-between;
    align-items: flex-start;
  }
  .writeReview{
    padding: 0px 10px;
  }


}

.InputReview>textarea{
  max-width: 100%;
  outline: 1px solid black;
  box-sizing: border-box;
  padding: 20px 20px;
  font-size: large;
  font-weight: 500;
  border-radius: 20px;
}

.ReviewTextArea{
  max-width: 100%;
}

@media screen and (max-width:375px) {
  .ProductDetailsDiv{
    padding: 20px 20px;
  }

  .RatigsStars{
    padding: 10px 20px !important;
  }

  .CustomerHeader{
    font-size: large !important;
  }
}

@media screen and (max-width:580px) {
  .RatingsWrap{
    padding: 10px 0px;
  }
  .RatingsStarDivWrap{
    padding: 10px 30px;
  }
}

@media screen and (max-width:490px) {
  .RatingsStarDivWrap{
    padding: 10px 0px;
  }
  .CustomerHeader{
    font-size: x-large !important;
  }
}

.ReviewDate>p{
  font-size: smaller;
  color: rgba(0, 0, 0, 0.518);
}